import classes from "./contactus6.module.scss";
import React, {useRef, useState} from 'react';
import emailjs from 'emailjs-com';

export const Contactus6 = () => {
    const form = useRef();


    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [name, setName] = useState("");

    const [alert, setAlert] = useState(false);

    const clickAlert = () => {
        setAlert(current => !current);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleTelChange = (event) => {
        setTel(event.target.value);
    };


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vm89157', 'contact_form', form.current, 'sf0N11FUQvZlxSxx4')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    function refreshPage() {
        window.location.reload();
    }

    return (
        <div>
            <form ref={form} onSubmit={sendEmail} className={classes.Contactus}>
                <ul className={classes.Contactus__block}>
                    <li className={classes.Contactus__block__li1}>
                        <h2 className={classes.Contactus__block__text1}>Напишите нам и мы Вам ответим</h2>
                    </li>
                    <li className={classes.Contactus__block__li1}>
                        <label>Имя</label>
                    </li>
                    <li className={classes.Contactus__block__li2}>
                        <input className={classes.Contactus__block__text3} type="text" name="user_name"
                               onChange={handleNameChange}
                               value={name}
                               placeholder='обязательно к заполнению'/>
                    </li>
                    <li className={classes.Contactus__block__li3}>
                        <label>Телефон</label>
                    </li>
                    <li className={classes.Contactus__block__li4}>
                        <input className={classes.Contactus__block__text5} type="tel" name="user_tel"
                               onChange={handleTelChange}
                               value={tel}
                               placeholder='обязательно к заполнению'/>
                    </li>
                    <li className={classes.Contactus__block__li5}>
                        <label>Электронная почта</label>
                    </li>
                    <li className={classes.Contactus__block__li6}>
                        <input className={classes.Contactus__block__text7} type="email" name="user_email"
                               onChange={handleEmailChange}
                               value={email}
                               placeholder='обязательно к заполнению'/>
                    </li>
                    <li className={classes.Contactus__block__li7}>
                        <label>Ваш выбор</label>
                    </li>
                    <li className={classes.Contactus__block__li8}>
                        <select className={classes.Contactus__block__text9} name="user_profile">
                            <option value="Без выбора"></option>
                            <option value="Пакет Старт">Пакет "Старт"</option>
                            <option value="Пакет Старт Плюс">Пакет "Старт Плюс"</option>
                            <option value="Пакет Старт Плюс">Пакет "Старт Вместе"</option>
                            <option value="У меня есть вопрос">У меня есть вопрос</option>
                            <option value="Перезвоните мне">Перезвоните мне</option>
                        </select></li>
                    <li className={classes.Contactus__block__li9}>
                        <label>Комментарии</label>
                    </li>
                    <li className={classes.Contactus__block__li10}>
                        <textarea className={classes.Contactus__block__text11} name="message"/>
                    </li>
                    <li className={classes.Contactus__block__li11}>
                        <input className={classes.Contactus__block__text12}
                               disabled={!email || !tel || !name}
                               type="submit" value="отправить"
                               onClick={clickAlert}/>
                        <div className={classes.Contactus__block__block1} style={{display: alert ? 'block' : 'none'}}>
                            <p className={classes.Contactus__block__block1__text1}>Ваше сообщение принято</p>
                            <p className={classes.Contactus__block__block1__text1}>В ближайшее время мы с Вами
                                свяжемся</p>
                            <p className={classes.Contactus__block__block1__text1}>Спасибо что выбрали нас</p>
                            <button type="button" onClick={refreshPage} className={classes.Contactus__block__block1__btn1}>
                                Нажмите, если видете это сообщение
                            </button>
                        </div>
                    </li>
                    <p className={classes.Contactus__block__text13}>
                        Нажимая "отправить" Вы соглашаетесь с условиями обработки персональных данных
                    </p>
                </ul>
            </form>
        </div>
    );
};