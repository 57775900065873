import React from 'react';
import classes from "./footer.module.scss";

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <p className={classes.Footer__text2}>+7(901)-187-80-27</p>
            <p className={classes.Footer__text3}>телеграм: @FF_EST</p>
            <p className={classes.Footer__text1}>Создано в 2022 году</p>
        </div>
    );
};

export default Footer;