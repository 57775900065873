import React from 'react';
import classes from "./seo.module.scss";
import {Link} from "react-router-dom";
import image4 from "../../image/rm222batch2-mind-03-min.jpg";

const Seo = () => {
    return (
        <div className={classes.Seo}>

            <img className={classes.Seo__img1} src={image4} alt='text'/>
            <Link to='/'><p className={classes.Seo__block1}>Вернуться на главную</p></Link>
            <h1 className={classes.Seo__text1}>Срок и условия работы.</h1>
            {/*<h2 className={classes.Seo__text2}>1. Сколько стоит реклама в Яндекс Директ?</h2>*/}
            {/*<h2 className={classes.Seo__text3}>2. Какой нужен бюджет на Яндекс Директ?</h2>*/}
            {/*<h3 className={classes.Seo__text4}>1. Забор товара</h3>*/}
            <p className={classes.Seo__text5}>
                1. Забор товара.<br/>
                Для того, чтобы мы забрали Ваш товар вовремя, Вам необходимо предоставить полную информацию
                по забору, а именно телефон у кого забираем, номер груза, вес, объём и количество мест.
                Мы забираем товар день в день.<br/><br/>
                2. Срок обработки товара.<br/>
                Срок обработки товара начинает исчесляться с момента когда товар был получен и на него
                подготовлены таблица товаров и техническое задание.<br/><br/>
                3. Заказ отгрузки.<br/>
                Для того, чтобы отгрузка состоялась необходимо заполнить таблицу отгрузки.<br/><br/>
                4. Срок выполнения заказа.<br/>
                Средний срок выполнения заказа 3 дня с момента подготовки всего необходимого для этого.
                При этом обращаем Ваше внимание на то, что срок может быть как меньше, так и больше,
                в зависимости от необходимой обработки товара.<br/><br/>
                5. Оплата выполненных работ.<br/>
                Оплата производится после отгрузки на МП.
                При этом скидка при первом заказе будет 10%. Далее условия оплаты по договоренности.
            </p>
            {/*<p className={classes.Seo__text6}>Стоимость ведения компании — оплачивается каждый месяц.*/}
            {/*    Собственно, в ведение рекламы входит (но не ограничивается):*/}
            {/*    Тестирование разных форматов кампании (поисковая реклама, РСЯ, смарт-баннеры,*/}
            {/*    медийная реклама и другие);*/}
            {/*    Тестирование разных стратегий и настроек рекламы;*/}
            {/*    Тестирование и отслеживание эффективности в разрезе ключевых слов,*/}
            {/*    объявлений, сегментов аудиторий по полу/возрасту/платежеспособности,*/}
            {/*    устройств, регионов и т.д.*/}
            {/*    Управление ставками и бюджетом;*/}
            {/*    Тестирование новых функций, которые регулярно появляются в Яндексе;*/}
            {/*    Составление отчетов;*/}
            {/*    Поиск новых точек роста.*/}
            {/*    Если говорить проще, то цель ведения рекламы — это оптимизировать рекламу так,*/}
            {/*    чтобы получали как можно больше результатов в рамках бюджета.*/}
            {/*</p>*/}
            {/*<p className={classes.Seo__text7}>Сумма, которая непосредственно тратится на показы/клики в*/}
            {/*    рекламном кабинете — тратится постоянно, пока работает реклама. Когда считают бюджет на*/}
            {/*    Яндекс Директ, обычно отталкиваются в первую очередь от стоимости клика. На стоимость*/}
            {/*    клика в Директе влияет регион, площадка, на которой показывается объявление, ниша*/}
            {/*    и правильность настроек кампании. Чем дороже ниша и регион, тем дороже клики в рекламе.*/}
            {/*    Например, за рекламу частного детского садика в Москве на поиске Яндекса может взиматься*/}
            {/*    500-700 рублей за 1 клик! А за клик по недорогому товару где-то в Самаре в рекламной сети*/}
            {/*    Яндекса (РСЯ) может взиматься 5-7 рублей. При этом даже в Москве по высококонкурентному*/}
            {/*    продукту можно добиться стоимости клика в 5 рублей, единственное это займет больше времени*/}
            {/*    для ротации сайта в поиске.*/}
            {/*</p>*/}
            {/*<span className={classes.Seo__text8}></span>*/}
            {/*<span className={classes.Seo__text9}></span>*/}
        </div>
    );
};

export default Seo;