import React from 'react';
import classes from "./sale.module.scss";
import {Link} from "react-router-dom";
import image4 from "../../image/rm222batch2-mind-03-min.jpg";

const Sale = () => {
    return (
        <div className={classes.Sale}>
            <img className={classes.Sale__img1} src={image4} alt='text'/>
            <Link to='/'><p className={classes.Sale__block1}>Вернуться на главную</p></Link>
            <h1 className={classes.Sale__text1}>Фулфилмент EST</h1>
            <h2 className={classes.Sale__text2}>Схема работы</h2>
            <h2 className={classes.Sale__text3}>Последовательность совместных действий для достижения результата</h2>
            <p className={classes.Sale__text4}><p>1 этап. Предварительный</p>
                В формате онлайн мы знакомимся, немного расказываем о себе и узнаем о Вас.
                Далее уточняем вид товара, количество товара, место забора товара,
                необходимую обработку, упаковку и срок отгрузки.
            </p>
            <p className={classes.Sale__text5}><p>2 этап. Подготовительный</p>
                На этапе подготовки от Вас потребуется хорошая коммуникация и
                предоставления как можно полной информации о товаре.
                Мы Вам создадим чат, присвоим номер, отправим для заполнения спецефикацию товара и
                техническое задание на обработку. На этом этапе мы поможем Вам всё верно заполнить.
            </p>
            <p className={classes.Sale__text6}><p>3 этап. Забор товара</p>
                Вы нам предоставите полную информацию для того, чтобы мы смогли забрать Ваш товар.
                Нам необходимо знать: место забора, время работы или телефон по которому это можно уточнить,
                количество мест, объём мешков или коробов (или фото), вес товара, а также фото чека об
                оплапте товара.
            </p>
            <p className={classes.Sale__text7}><p>4 этап. Основная обработка</p>
                На этом этапе потребуется небольшое Ваше участие, для согласования некоторых моментов.
                Будет произведён пересчёт товара, проверка на брак, обработка, фасовка, упаковка и
                маркировка (при необходимости биркитирование).
            </p>
            <p className={classes.Sale__text8}><p>5 этап. Подготовка к отгрузке</p>
                Очень ответственный этап. Именно на этом этапе Вы будете формировать поставку для отгрузки.
                Мы предоставим Вам данные и если необходимо обучающий материал по этому вопросу. Итогом будет,
                сформированные короба, на которых будет наклеин ШК коробов и ШК ТТН.
            </p>
            <p className={classes.Sale__text9}><p>6 этап. Отгрузка на склад МП ВБ</p>
                Наша логистическая службв отправляет товар для отгрузки на склад ВБ.
                По результатам отгрузки мы Вам отпишемся и сообщим, что товар отгружен
            </p>
            <span className={classes.Sale__text10}></span>
            <span className={classes.Sale__text11}></span>
            <span className={classes.Sale__text12}></span>
            <span className={classes.Sale__text13}></span>
            <span className={classes.Sale__text14}></span>
            <span className={classes.Sale__text15}></span>
            <span className={classes.Sale__text16}></span>
        </div>
    );
};

export default Sale;