import React from 'react';
import {Link} from "react-router-dom";
import Term from "../page/term/term";
import Sale from "../page/sale/sale";
import Contacts from "../page/contacts/contacts";
import classes from "./menu.module.scss";

const Menu = () => {
    return (
        <div className={classes.Menu}>
            <div className={classes.Menu__link1}>
                <Link to='home'></Link>
            </div>
            <div className={classes.Menu__link2}>
                <Link  to='about' element={<Term/>}>О нас</Link>
            </div>
            <div className={classes.Menu__link3}>
                <Link  to='sale' element={<Sale/>}>Схема работы</Link>
            </div>
            <div className={classes.Menu__link4}>
                <Link  to='seo' element={<Term/>}>Сроки и условия работы</Link>
            </div>
            <div className={classes.Menu__link5}>
                <Link  to='term' element={<Term/>}>Отзывы</Link>
            </div>
            <div className={classes.Menu__link6}>
                <Link  to='contacts' element={<Contacts/>}>Контакты</Link>
            </div>
        </div>
    );
};

export default Menu;