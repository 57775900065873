import React, {useState} from 'react';
import classes from "./menu1.module.scss";
import {Link} from "react-router-dom";
import Term from "../page/term/term";
import Sale from "../page/sale/sale";
import Contacts from "../page/contacts/contacts";

const Menu1 = () => {

    const [state, setState] = useState(false)

    const onclickHandler = () => {
        setState(current => !current)
    };

    return (
        <div className={classes.Menu1}>
            <button onClick={onclickHandler} className={classes.Menu1__btn1}>Меню</button>
            <div style={{display: state ? 'block' : 'none'}} className={classes.Menu1__block1}>
                <Link  className={classes.Menu1__block1__text1} to='home'></Link><br/>
                <Link className={classes.Menu1__block1__text1} to='about' element={<Term/>}>- О нас</Link><br/><br/>
                <Link className={classes.Menu1__block1__text1} to='sale' element={<Sale/>}>- Схема работы</Link><br/><br/>
                <Link className={classes.Menu1__block1__text1} to='seo' element={<Term/>}>
                    - Сроки и условия работы</Link><br/><br/>
                <Link className={classes.Menu1__block1__text1} to='term' element={<Term/>}>
                    - Отзывы</Link><br/><br/>
                <Link className={classes.Menu1__block1__text1} to='contacts' element={<Contacts/>}>- Контакты</Link>
            </div>
        </div>
    );
};

export default Menu1;