import React from 'react';
import classes from "./contacts.module.scss";
import {Link} from "react-router-dom";
import img3 from '../../image/phone.png';
import img5 from '../../image/telegram.png'

const Contacts = () => {
    return (
        <div className={classes.Contacts}>
            <Link to='/'><p className={classes.Contacts__text1}>Вернуться на главную</p></Link>
            <h1 className={classes.Contacts__text}>Наши контакты</h1>
            <div className={classes.Contacts__block}>
                <div className={classes.Contacts__block__block1}>
                    <img className={classes.Contacts__block__block1__img1} src={img5}/>
                    <a href="tg://resolve?domain=<USERNAME>" className={classes.Contacts__block__block1__text2}>@FF_EST</a>
                </div>
                <div className={classes.Contacts__block__block2}>
                    <img className={classes.Contacts__block__block2__img2} src={img3}/>
                    <a className={classes.Contacts__block__block2__text3} href='tel: +79011878027'>
                        +7(901)187-80-27</a>
                </div>
                <div className={classes.Contacts__block__block3}>
                   <p>Пишите или звоните будем рады сотрудничеству!!!</p>
                </div>
            </div>
        </div>
    );
};

export default Contacts;