import React from 'react';
import classes from "./about.module.scss";
import {Link} from "react-router-dom";
import image4 from '../../image/rm222batch2-mind-03-min.jpg'

const About = () => {
    return (
        <div className={classes.About}>
            <img className={classes.About__img1} src={image4} alt='text'/>
            <Link to='/'><p className={classes.About__block1}>Вернуться на главную</p></Link>
            <h1 className={classes.About__block1__text1}>О НАС</h1>
            <div className={classes.letter__image}>
                <div className={classes.animated__mail}>
                    <div className={classes.back__fold}></div>
                    <div className={classes.letter}>
                        <p className={classes.letter__text1}>Мы команда профессионалов</p>
                    </div>
                    <div className={classes.top__fold}></div>
                    <div className={classes.body}></div>
                    <div className={classes.left__fold}></div>
                </div>
            </div>
            <div className={classes.letter1__image}>
                <div className={classes.animated1__mail}>
                    <div className={classes.back1__fold}></div>
                    <div className={classes.letter1}>
                        <p className={classes.letter1__text1}>Мы 2 года занимаемся своим делом</p>
                    </div>
                    <div className={classes.top1__fold}></div>
                    <div className={classes.body1}></div>
                    <div className={classes.left1__fold}></div>
                </div>
            </div>
            <div className={classes.letter2__image}>
                <div className={classes.animated2__mail}>
                    <div className={classes.back2__fold}></div>
                    <div className={classes.letter2}>
                        <p className={classes.letter2__text1}>Мы умеем создавать эффективную систему</p>
                    </div>
                    <div className={classes.top2__fold}></div>
                    <div className={classes.body2}></div>
                    <div className={classes.left2__fold}></div>
                </div>
            </div>
            <div className={classes.letter3__image}>
                <div className={classes.animated3__mail}>
                    <div className={classes.back3__fold}></div>
                    <div className={classes.letter3}>
                        <p className={classes.letter3__text1}>Мы создаем результат</p>
                    </div>
                    <div className={classes.top3__fold}></div>
                    <div className={classes.body3}></div>
                    <div className={classes.left3__fold}></div>
                </div>
            </div>
            <div className={classes.letter4__image}>
                <div className={classes.animated4__mail}>
                    <div className={classes.back4__fold}></div>
                    <div className={classes.letter4}>
                        <p className={classes.letter4__text1}>Мы ставим задачи и выполняем их</p>
                    </div>
                    <div className={classes.top4__fold}></div>
                    <div className={classes.body4}></div>
                    <div className={classes.left4__fold}></div>
                </div>
            </div>
            <div className={classes.letter5__image}>
                <div className={classes.animated5__mail}>
                    <div className={classes.back5__fold}></div>
                    <div className={classes.letter5}>
                        <p className={classes.letter5__text1}>Мы целеустремлённая команда</p>
                    </div>
                    <div className={classes.top5__fold}></div>
                    <div className={classes.body5}></div>
                    <div className={classes.left5__fold}></div>
                </div>
            </div>
            <div className={classes.letter6__image}>
                <div className={classes.animated6__mail}>
                    <div className={classes.back6__fold}></div>
                    <div className={classes.letter6}>
                        <p className={classes.letter6__text1}>Мы за честность во всём</p>
                    </div>
                    <div className={classes.top6__fold}></div>
                    <div className={classes.body6}></div>
                    <div className={classes.left6__fold}></div>
                </div>
            </div>
            <div className={classes.letter7__image}>
                <div className={classes.animated7__mail}>
                    <div className={classes.back7__fold}></div>
                    <div className={classes.letter7}>
                        <p className={classes.letter7__text1}>Мы вместе построим наше дело</p>
                    </div>
                    <div className={classes.top7__fold}></div>
                    <div className={classes.body7}></div>
                    <div className={classes.left7__fold}></div>
                </div>
            </div>
        </div>
    );
};

export default About;