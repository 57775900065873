import React from 'react';
import classes from "./rulls1.module.scss";
import {Link} from "react-router-dom";

const Rulls1 = () => {
    return (
        <div className={classes.Rulls1}>
            <Link to='/'><p className={classes.Rulls1__block1}>Вернуться на главную</p></Link>
            <p className={classes.Rulls1__block2}>1. Определения и термины <br/>
                1.1. Сайт – любой из сайт, на котором размещена информация о предлагаемых товарах, услугах
                маркетинговых акциях и иной информации в сети Интернет.<br/>

                1.2. Клиент – физическое лицо или юридическое лицо, использующее сайт.<br/>

                1.3. Персональные данные - информация, относящаяся к определенному Клиенту, указанная
                в п. 3.1 настоящего Положения.<br/>

                1.4. Обработка персональных данных – любые операции, совершаемые с использованием средств
                автоматизации или без использования таких средств с персональными данными,
                включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение персональных данных.<br/>

                1.5. Cookies - фрагменты данных, отправляемых веб-сервером браузеру при посещении сайта Клиентом.<br/>

                2. Цели и принципы политики конфиденциальности и сбора персональных данных<br/>
                2.1. Политика конфиденциальности действует в отношении любой указанной в разделе 3 информации,
                которую
                сайт может получить о Клиенте во время использования сайта, программ и продуктов сайта.<br/>

                2.2. Клиент предоставляет свои персональные данные с целью:<br/>

                - создания учетной записи;

                - предоставления технической поддержки, связанной с использованием сайта;

                - участия в рекламных акциях, опросах;

                - оформления заказов, уведомления о состоянии заказов, обработки и получения платежей;

                - получения новостей, информации о продуктах, мероприятиях, рекламных акциях или услугах;

                - использования иных имеющихся на сайте сервисов, включая форум, персональные блоги, сервис обмена
                личными сообщениями между зарегистрированными участниками, персонализированные комментарии и отзывы,
                но
                не ограничиваясь ими.<br/>

                2.3. Обеспечение надежности хранения информации и прозрачности целей сбора персональных данных.
                Персональные данные Клиентов собираются, хранятся, обрабатываются, используются, передаются и
                удаляются
                (уничтожаются) в соответствии с законодательством РФ, в т.ч. Федеральным законом 27.07.2006 № 152-ФЗ
                «О
                персональных данных», и настоящей Политикой конфиденциальности.<br/>

                3. Информация, подлежащая обработке<br/>
                3.1. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                предоставляются Клиентом путём заполнения регистрационной формы на сайте Компании и включают в себя
                следующую информацию:<br/>

                3.1.1. ФИО Клиента;<br/>

                3.1.2. контактный телефон Клиента;<br/>

                3.1.3. адрес электронной почты (e-mail);<br/>

                3.1.4. адрес доставки Товара;<br/>

                3.1.5. историю заказов.<br/>

                3.2. Компания также получает данные, которые автоматически передаются в процессе просмотра при
                посещении
                сайта, в т. ч.:<br/>

                3.2.1. IP адрес;<br/>

                3.2.2. информация из cookies;<br/>

                3.2.3. информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);<br/>

                3.2.4. время доступа.<br/>

                4. Обработка и использование персональных данных<br/>
                4.1. Обработка персональных данных Клиента осуществляется без ограничения срока, любым законным
                способом, в том числе в информационных системах персональных данных с использованием средств
                автоматизации или без использования таких средств.<br/>

                4.2. Соглашаясь с настоящей Политикой конфиденциальности Клиент предоставляет Компании свое согласие
                на
                обработку указанных в разделе 3 персональных данных всеми указанными в настоящей Политике способами,
                а
                также передачу указанных данных партнерам Компании для целей исполнения принятых на себя
                обязательств.<br/>

                4.3. Компания не вправе передавать информацию о Клиенте неаффилированным лицам или лицам, не
                связанным с
                Компанией договорными отношениями.<br/>

                4.4. Передача информации аффилированным лицам и лицам, которые связаны с Компанией договорными
                отношениями (курьерские службы, организации почтовой связи и т.д.), осуществляется для исполнения
                заказа
                Клиента, а также для возможности информирования Клиента о проводимых акциях, предоставляемых
                услугах,
                проводимых мероприятиях.<br/>

                4.5. Аффилированные лица и лица, связанные с Компанией договорными отношениями, принимают на себя
                обязательства обеспечивать конфиденциальность информации и гарантировать ее защиту, а также
                обязуются
                использовать полученную информацию исключительно для целей исполнения указанных действий или
                оказания
                услуг.<br/>

                4.6. Компания принимает все необходимые меры для защиты персональных данных Клиента от
                неавторизованного
                доступа, изменения, раскрытия или уничтожения.<br/>

                5. Права и обязанности Клиента<br/>
                5.1. Клиент обязуется не сообщать каким-либо третьим лицам логин и пароль, используемые им для
                идентификации на сайте Компании.<br/>

                5.2. Клиент обязуется соблюдать должную осмотрительность при хранении пароля, а также при его
                вводе.<br/>

                5.3. Клиент вправе изменять свои личные данные, а также требовать удаление личных данных у
                Компании.<br/>

                6. Дополнительные условия<br/>
                6.1. Соглашаясь с настоящей Политикой конфиденциальности, Клиент предоставляет свое согласие на
                получение информации о состоянии заказов, учетной записи и прочих уведомлений технического
                характера, а
                также уведомлений рекламного характера, в том числе о текущих маркетинговых акциях и актуальных
                предложениях Компании, с помощью различных средств, включая SMS и электронную почту, но не
                ограничиваясь
                ими. Клиент может в любое время отказаться от получения такой информации путем изменения данных
                учетной
                записи на сайте Компании.<br/>

                6.2. Срок действия согласия определяется достижением целей обработки либо отзывом согласия
                потребителем.<br/>

                6.3. Компания несет ответственность перед клиентом в случаях, предусмотренных действующим
                законодательством.<br/>

                6.4. Компания освобождается от ответственности в случаях, когда информация о Клиенте:<br/>

                - стала публичным достоянием до её утраты или разглашения;<br/>

                - была получена от третьей стороны до момента её получения Компанией;<br/>

                - была разглашена с согласия Клиента.<br/>

                6.5. Компания вправе вносить изменения в политику конфиденциальности в одностороннем порядке.
            </p>
        </div>
    )
        ;
};

export default Rulls1;