import classes from "./App.module.scss";
import {Route, Routes} from "react-router-dom";
import Home from "./components/page/home/home";
import Term from "./components/page/term/term";
import Sale from "./components/page/sale/sale";
import Contacts from "./components/page/contacts/contacts";
import About from "./components/page/about/about";
import Seo from "./components/page/seo/seo";
import Rulls1 from "./components/contactus/rulls1/rulls1";
import Rulls2 from "./components/contactus/rulls2/rulls2";

function App() {
    return (
        <Routes className={classes.App}>
            <Route path='/' element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/term' element={<Term/>}/>
            <Route path='/sale' element={<Sale/>}/>
            <Route path='/contacts' element={<Contacts/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/seo' element={<Seo/>}/>
            <Route path='/rulls1' element={<Rulls1/>}/>
            <Route path='/rulls2' element={<Rulls2/>}/>
        </Routes>
    );
}

export default App;
