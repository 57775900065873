import React, {useState} from 'react';
import classes from "./header1.module.scss";
import image1 from "../../image/telegram.png";
import image2 from "../../image/phone.png";
import image5 from "../../image/logo1.png";


const Header1 = () => {
    return (
        <div className={classes.Header}>
            {/*<div className={classes.Header__img1}>*/}
            {/*    <img className={classes.Header__img1__img1} src={image5}/>*/}
            {/*</div>*/}
            <div className={classes.Header__block}>
                <div className={classes.Header__block__text1}>Фулфилмент EST</div>
                <div className={classes.Header__block__text2}>ВМЕСТЕ МЫ ДОБЬЕМСЯ УСПЕХА И ПРОЦВЕТАНИЯ</div>
            </div>
            <div className={classes.Header__block1}>
                <img className={classes.Header__block1__img1} src={image1}/>
                <a href="tg://resolve?domain=<@FF_EST>" className={classes.Header__block1__text1}>@FF_EST</a>
                <img className={classes.Header__block1__img2} src={image2}/>
                <a className={classes.Header__block1__text2} href='tel: +79011878027'>
                    +7(901)187-80-27</a>
            </div>
        </div>
    );
};

export default Header1;