import React from 'react';
import classes from "./rulls2.module.scss";
import {Link} from "react-router-dom";

const Rulls2 = () => {
    return (
        <div className={classes.Rulls2}>
            <Link to='/'><p className={classes.Rulls2__block1}>Вернуться на главную</p></Link>
            <p className={classes.Rulls2__block2}>
                1. Общее положение<br/>
                1.1.Настоящие Правила продажи товаров в услуг (далее — «Правила») определяют порядок розничной
                купли-продажи Товаров и Услуг через Интернет-магазин, и в соответствии со ст. 437 Гражданского
                Кодекса РФ являются официальной публичной офертой, адресованной физическим и юридическим лицам,
                далее именуемым «Покупатель», при совместном упоминании Продавец и Покупатель также именуются
                «Стороны», а каждый по отдельности «Сторона».<br/>

                1.2. Заказывая Товары и Услуги через Интернет-магазин, Покупатель соглашается с Правилами, изложенными
                ниже.<br/>

                1.3. К отношениям между Покупателем и Продавцом применяются положения Гражданского Кодекса РФ
                (в т. ч. положение о розничной купле-продаже (§ 2, глава 30),
                Закон РФ «О защите прав потребителей» от 07.02.1992 № 2300-1,
                Постановление Правительства РФ от 31.12.2020 N 2463 и иные правовые акты, принятые в соответствии с
                ними.

                «О защите прав потребителей» от 07.02.1992 № 2300-1, Постановление
                Правительства РФ от 31.12.2020 N 2463 и иные правовые акты, принятые в соответствии с ними.<br/>

                1.4. Продавец оставляет за собой право вносить изменения в настоящие Правила в одностороннем
                порядке.<br/>

                1.5. Продавец доводит действующую версию настоящих правил до Покупателя.<br/>

                1.6. Покупатель обязуется до момента оформления Заказа в интернет-магазине ознакомиться
                с содержанием настоящих Правил, а также с иными условиями, указанными на сайте интернет-магазина,
                в том числе с ценами на услуги и товары, установленными в интернет-магазине.<br/>

                1.7. Покупатель подтверждает факт ознакомления и согласия с настоящими Правилами при
                оформлении Заказа посредством заполнения электронной формы Заказа.<br/>

                2. Оформление Заказа Покупателем.<br/>
                2.1. Заказ Покупателя может быть оформлен через сеть Интернет.<br/>

                2.2. Заказ может быть оформлен на Товары или Услуги, которые указаны в каталоге Продавца на сайте.<br/>

                2.3. Заказ оформляется путем заполнения Покупателем электронной формы Заказа на сайте Продавца.<br/>

                2.4. При оформлении Заказа Покупателем указывается:<br/>

                2.4.1. контактный номер телефона (обязательно);<br/>

                2.4.2. электронная почта (при наличии).<br/>

                2.5. Покупатель вправе выбрать способ получения Заказа из предложенных Продавцом.<br/>

                2.6. При выборе способа получения Заказа Самовывоз Покупатель может выбрать магазин Продавца
                или иной адрес для самовывоза из предложенных Продавцом.<br/>

                2.7. Для заказа Услуги Доставки (если такая необходима) Покупатель обязуется указать
                необходимые для оказания услуги данные:<br/>

                2.7.1. адрес (город, улица, номер дома, корпус, номер подъезда) - Возможность и способы доставки
                по указанному Покупателю адресу определяются в момент оформления Заказа;<br/>

                2.7.2. этаж;<br/>

                2.7.3. наличие лифта;<br/>

                2.7.4. желаемую дату и время доставки - Покупатель может выбрать желаемое время доставки из
                предложенных Продавцом при оформлении Заказа. Доставка может осуществляться в будние,
                выходные и праздничные дни, в случае, когда такая возможность указана Продавцом при
                оформлении Заказа.<br/>

                2.8. В случае приобретения Покупателем иных услуг Продавец вправе потребовать у Покупателя
                иную информацию, необходимую для оказания услуг.<br/>

                2.9. Оформляя Заказ, Покупатель подтверждает, что указанные в п.2.4, 2.7, 2.8
                сведения являются корректными и достоверными и заверяет, что, предоставляя
                такую информацию, не нарушает права третьих лиц.<br/>

                3. Подтверждение Заказа Продавцом
                3.1. После оформления на сайте, Продавец в срок до 3 (трех) рабочих дней обрабатывает
                направленный Покупателем Заказ.<br/>

                3.2. Заказ считается подтвержденным после получения Покупателем уведомления по смс
                и/или электронной почте, с указанием номера Заказа. До получения подтверждения
                Заказа, с указанием его номера, договор дистанционной купли-продажи считается не заключенным.<br/>

                3.3. В случае невозможности подтвердить Заказ Продавец вправе аннулировать его
                в течении 3 рабочих дней с даты его оформления и уведомить об этом Покупателя
                по смс и/или электронной почте.<br/>

                3.4. В случае невозможности подтверждения части Заказа, Продавец вправе в течение 3 (трех)
                рабочих дня с даты оформления Заказа информировать об этом Покупателя по телефону,
                смс и/или электронной почте, указанной при Оформлении Заказа и подтвердить Заказ частично.<br/>

                3.5. Продавец уведомляет Покупателя о подтверждении Заказа по смс и/или электронной почте, указанной при
                оформлении Заказа, о дате готовности Заказа, номере Заказа, готовности Заказа, сроках хранения Заказа.<br/>

                4. Оплата заказа
                4.1. Цена Товара/Услуги указывается рядом с определённым наименованием Товара/Услуги в
                Интернет-магазине.<br/>

                4.2. Цена Товара/Услуг указывается в рублях Российской Федерации.<br/>

                4.3. Цена Товара/Услуги в Интернет-магазине может быть изменена Продавцом в одностороннем порядке. Цена
                товара действительна на момент подтверждения заказа Покупателем. При этом цена на заказанный Покупателем
                Товар изменению не подлежит.<br/>

                4.4. Оплата Заказа может осуществлять как при оформлении Заказа, так и после подтверждения Заказа
                Продавцом. Продавец при оформлении Заказа может определять конкретный момент оплаты Заказа на свое
                усмотрение.<br/>

                4.5. Оплата Заказа Покупателем производится в рублях Российской Федерации.<br/>

                4.6. Форма возможной оплаты Заказа определяется по соглашению между Продавцом и Покупателем при
                оформлении Заказа и не должна противоречить действующему законодательству РФ.<br/>

                4.7. Оплата Заказа может производиться одним из следующих способов по выбору Покупателя:<br/>

                4.7.1. При выборе способа получения Заказа «Самовывоз» оплата возможна (для товаров):<br/>

                4.7.1.1 при получении - наличными денежными средствами, банковской картой, подарочной картой. <br/>

                4.7.1.2 в момент оформления Заказа на сайте - посредством сервисов интернет-эквайринга: онлайн-оплата
                банковской картой, оплата из кошелька ЮMoney, оплата через QIWI Wallet, оплата из кошелька WebMoney.
                (если такой имеется)<br/>

                4.7.2. Доступные способы оплаты при выборе Покупателем доставки Товара указываются на странице
                сайта Продавца.<br/>

                4.7.3. При получении Заказа через постомат, оплата производится при оформлении Заказа на сайте
                посредством сервисов интернет-эквайринга: онлайн-оплата банковской картой, оплата из кошелька ЮMoney,
                оплата через QIWI Wallet.(если такой имеется)<br/>

                4.7.4. Продавец вправе предложить Покупателю иные способы оплаты, не противоречащие требованиям
                действующего законодательства РФ.(если такой имеется)<br/>

                4.8. Оплата Заказа банковской картой производится через приложение.(если такой имеется)<br/>

                5. Получение Заказа
                5.1. Дата готовности Заказа определяется Продавцом в одностороннем порядке при оформлении Заказа.<br/>

                5.2. Продавец обязуется хранить Заказ Покупателя в течение срока хранения, указанного при оформлении
                Заказа. Неполучение Заказа в указанный срок считается отказом Покупателя от договора купли-продажи и
                является основанием для аннулирования Заказа Продавцом. Если неполученный Заказ был предоплачен,
                денежные средства возвращаются Покупателю в порядке, предусмотренном Настоящими Правилами.<br/>

                5.3. При оформлении Заказа Покупатель вправе выбрать любой из доступных способов получения заказа:
                самовывоз или доставка.<br/>

                Самовывоз:<br/>

                5.4. Выдача Заказа путем самовывоза осуществляется в любом из предложенных при оформлении Заказа
                Магазинов Продавца по выбору Покупателя.<br/>

                5.5. О готовности Заказа к самовывозу Продавец уведомляет Покупателя путем направления смс – сообщения
                на номер, указанный при оформлении Заказа.<br/>

                5.6. Продавец вправе осуществить идентификацию Покупателя при выдаче Заказа. Для идентификации
                покупателя Продавец вправе по собственному усмотрению направить на номер телефона, указанный при
                оформлении Заказа на сайте Продавца, уникальный код для получения Заказа. Выдача Заказа в таком случае
                осуществляется при предоставлении указанного кода Покупателем.<br/>

                5.7. Покупатель самостоятельно несёт ответственность за сохранность полученного уникального кода и не
                вправе сообщать указанный код третьим лицам. Риски, связанные с нарушением обязательств по сохранности
                уникального кода, Покупатель несет самостоятельно.<br/>

                5.8. Если код для получения Заказа утерян Покупатель может сообщить об этом продавцу при получении
                Заказа. В указанном случае Продавец может повторно отправить его на номер телефона, указанный
                Покупателем при оформлении Заказа. Действующим будет считаться код, направленный последним, все ранее
                направленные коды утрачивают силу, а выдача Заказа по ним не осуществляется.<br/>

                5.9. Код для получения Заказа может быть использован только один раз.<br/>

                5.10. Продавец может без применения к нему каких – либо мер ответственности отказать в выдаче Заказа в
                случаях, когда код для получения не назван или назван неверно.<br/>

                Постаматы Продавца:<br/>

                5.11. Продавец вправе – по своему усмотрению, разместить Заказ в Постамате Продавца. Продавец уведомляет
                Покупателя о размещении Заказа в Постамате в смс – сообщении после уведомления о готовности Заказа к
                самовывозу.<br/>

                5.12. Получения Заказа в Постамате Продавца возможно исключительно использованием Терминала
                самообслуживания. Для получения Заказа Покупателю необходимо выбрать опцию «Получить заказ» и ввести
                номер Заказа, указанный в смс – сообщении и выбрать опцию «Получить».<br/>

                5.13. Продавец вправе осуществить идентификацию Покупателя для выдачи Заказа через Постаматы Продавца.
                Для идентификации покупателя Продавец вправе по собственному усмотрению направить на номер телефона,
                указанный при оформлении Заказа на сайте Продавца, уникальный код для получения Заказа. Покупателю
                необходимо внести указанный код в поле «Введите СМС – код».
                Выдача Заказа осуществляется при корректном указании указанного кода Покупателем.<br/>

                5.14. Код для получения Заказа в Постамате активен в течении 5 (пяти) минут с момента его получения и
                может быть использован только один раз. По истечении 5 (пяти) минут Покупатель может запросить новый код
                путем выбора опции «Обновить СМС - код».
                Покупатель может запросить код повторно не ранее чем через 2 минуты после получения кода и не более 3
                раз подряд.<br/>

                Доставка:<br/>

                5.15. Доставка осуществляется в выбранном Покупателем при оформлении Заказа временном интервале в любой
                момент на усмотрение Продавца. Покупатель не вправе требовать осуществления доставки в конкретное время
                в рамках выбранного интервала.<br/>

                5.16. При выполнении доставки осуществляется информирование Покупателя звонком по номеру телефона,
                указанному при оформлении Заказа, не менее чем за 1 (один) час до прибытия на адрес Доставки.<br/>

                5.17. Доставка осуществляется по адресу, указанному Покупателем при оформлении Заказа.<br/>

                5.18. Доставка осуществляется до указанного адреса при наличии организованных подъездных путей,
                пригодных для передвижения транспорта. В случае невозможности подъезда транспорта к указанному адресу
                Продавец вправе передать Заказ в удобном для Продавца месте.<br/>

                5.19. Если условия доставки предполагают подъем на этаж, Покупатель обязуется обеспечить наличие
                лестниц, лифтов, иного строительного или технологического оснащения для выполнения подъема. Лестничные
                проходы, пролеты, проёмы дверей должны быть свободны для перемещения Заказа в транспортной упаковке. В
                случае отсутствия оснащения для подъёма или в случае, если подъем Заказа в транспортной упаковке не
                возможен Продавец вправе передать Заказу входа в подъезд/жилой дом.<br/>

                5.20. Заказ заносится за порог входной двери Помещения, иные перемещения Заказа по желанию Покупателя не
                осуществляются. Проем входной двери должен быть свободен для перемещения Заказа в транспортной упаковке.
                В случае если габариты проема входной двери не позволяют занести Заказ в транспортной упаковке в
                помещение, Продавец вправе передать Заказ у входа в Помещение.<br/>

                5.21. При получении Заказа Покупатель должен проверить внешний вид и упаковку Заказа, количество Товаров
                в Заказе, комплектность, ассортимент, целостность.<br/>

                5.22. При получении Заказа Покупатель обязуется подписать сопроводительные документы. При доставке
                Заказа Покупателю будет передан комплект сопроводительных документов:<br/>

                5.22.1. товарный чек,<br/>

                5.22.2. транспортная накладная или наряд на выдачу.<br/>

                Товарный чек передается Покупателю в одном экземпляре. Транспортная накладная или наряд на выдачу
                подписывается в двух экземплярах. Один экземпляр передается Покупателю. Второй экземпляр остается у
                Продавца.<br/>

                5.23. При наличии претензий к внешнему виду и комплектности Заказа Покупатель вправе отказаться от
                приема Заказа.<br/>

                5.24. В случаях если Покупатель необоснованно уклоняется от принятия Заказа или связаться с Покупателем
                по указанным при оформлении услуг Доставки данным не представляется возможным, Продавец вправе
                аннулировать Заказ и уведомить об этом клиента по смс и/или электронной почте, указанной при Оформлении
                Заказа.<br/>

                5.25. Доставку осуществляет Продавец своими силами, либо с привлечением Службы доставки. Под «Службой
                доставки» подразумевается лицо, оказывающее по договору с Продавцом услуги доставки
                Заказа Покупателям.<br/>

                5.26. Оформляя Заказ на сайте Покупатель признает и подтверждает, что в случае необходимости Продавец –
                 для достижения целей доставки вправе предоставлять персональные данные покупателя
                третьим лицам, привлеченных к оказанию услуг в целях доставки (п.5.20). Такие лица имеют право на
                обработку персональных данных на основании согласия на обработку персональных данных, описанного в
                разделе «Персональные данные».<br/>

                5.27. Оформляя Заказ на сайте Покупатель подтверждает, что во взаимоотношениях между Продавцом и
                Покупателем, связанных с приобретением товаров/услуг, выдачей и доставкой Заказа, а также в иных
                случаях, предусмотренных настоящими правилами, вместо документов на бумажном носителе, могут
                использоваться электронные документы, удостоверенные ПЭП Покупателя. Под ПЭП Покупателя подразумевается
                простая электронная подпись, которая посредством использования СМС-кодов подтверждает факт формирования
                электронной подписи определенным лицом, согласно статье 5 федерального закона 63-ФЗ «Об электронной
                подписи» от 06.04.2011 года. Электронные документы, подписанные ПЭП Покупателя и переданные Продавцу с
                использованием электронных сервисов (в т.ч. сервисов третьих лиц), признаются Продавцом и Покупателем
                равнозначными документами на бумажном носителе, подписанным собственноручной подписью, и могут служить
                доказательством в суде. Для подтверждения получения Заказа, Покупатель/Продавец/Служба доставки
                используют ключ простой электронной подписи, в виде числовых, буквенных, символьных или комбинированных
                кодов, сформированных и направленных Продавцом / Службой доставки и полученных Покупателем в виде
                сообщения на номер телефона, указанный Покупателем при оформлении Заказа (далее – «СМС-коды»).
                Покупатель подтверждает, что идентификация, проводимая на Сайте www.dns-shop.ru с использованием
                указанных Покупателем идентификационных данных, является действительной и достаточной для предоставления
                доступа к средствам ПЭП, а также подтверждает, что номер телефона, указанный им при оформлении Заказа
                принадлежит Покупателю и является достаточным для его идентификации. Покупатель обязуется соблюдать
                конфиденциальность при использовании СМС-кода, а также не передавать физический носитель с СМС-кодом или
                СМС-код, поступивший в электронном виде третьим лицам (за исключением Службы доставки/Продавца), во
                избежание нарушения конфиденциальности Смс-кода. Риски, связанные с доставкой Заказа, в случае нарушения
                Покупателем условий конфиденциальности при использовании СМС-кода, возлагаются на Покупателя. При
                приемке Заказа Покупатель должен сообщить Продавцу или Службе доставки полученный СМС-код для получения
                Заказа. В случае корректности СМС-кода, подтвержденного Продавцом/Службой доставки посредством
                функционала электронного сервиса Продавца или Службы доставки, Заказа считается переданным Продавцом /
                Службой доставки и принятым Покупателем. В соответствующих полях электронного документа о
                приеме-передаче Заказа (Отправлений) проставляются электронные подписи. Все электронные документы,
                предоставляемые Покупателю Продавцом или Службой доставки, являются подписанными ПЭП Покупателя. ПЭП
                Покупателя в документах, подтверждающих получение Заказа, означает отсутствие претензий Покупателя к
                количеству, внешнему виду, ассортименту и комплектности Заказа.<br/>

                6. Прочие условия<br/>
                6.1. В случае приобретения Покупателем товара по акции, определенные Продавцом в акции, условия
                приобретения товара являются для сторон существенными условиями заключаемого договора купли-продажи (ч.1
                ст.432 Гражданского кодекса РФ).<br/>

                6.2. Продавец вправе в одностороннем порядке ограничить количество товарных позиций в одном заказе,
                сумму одного заказа, а также количество заказов, единовременно отправляемых на один адрес одному
                Покупателю.<br/>

                6.3. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего
                использования Товаров, приобретённых в Интернет-магазине.<br/>

                6.4. Продавец не отвечает за убытки Покупателя, возникшие в результат неправильного заполнения
                бланка-заказа, в т. ч. неправильного указания персональных данных, а также неправомерных действий
                третьих лиц.<br/>

                6.5. При оформлении Заказа Покупатель подтверждает, что приобретает товар исключительно для личных,
                семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.

                6.6. Порядок гарантийного обслуживания приобретенного Покупателем Товара и связанные с таким
                обслуживанием права Покупателя указываются на странице Интернет-магазина Продавца.<br/>

                6.7. Порядок обмена и возврата Товара, приобретенного Покупателем, указывается на странице
                Интернет-магазина Продавца.<br/>

                6.8. При обращении Покупателя в Сервисные центры Продавца за гарантийным обслуживанием, возвратом или
                обменом товара, приобретенного он – лайн, без предоставления сведений о дате и номере Заказа, Продавец –
                по запросу Покупателя – вправе восстановить сведения о дате и номере Заказа путем направления смс с
                проверочным кодом на номер телефона, указанный при оформлении Заказа. Покупателю необходимо сообщить
                указанный проверочный код сотруднику сервисного центра Продавца.<br/>

                6.9. Все претензии, связанные с заключенным договором розничной купли – продажи, Покупатель обязуется
                направлять исключительно в простой письменной форме на адрес обособленного подразделения Продавца –
                магазин самовывоза, либо на адрес для претензий, указанный в товарном чеке.<br/>

                6.10. Стороны освобождаются от ответственности за полное или частичное неисполнение своих обязательств,
                если такое неисполнение явилось следствием действия обстоятельств непреодолимой силы, возникших после
                вступления в силу Правил, в результате событий чрезвычайного характера, которые Стороны не могли
                предвидеть и предотвратить разумными мерами.<br/>

                6.11. К отношениям между Покупателем и Продавцом применяется законодательство Российской Федерации.<br/>

                6.12. Оформляя Заказа, Покупатель гарантирует и подтверждает, что все условия настоящих Правил ему
                понятны, и он принимает их безусловно и в полном объёме.<br/>

                6.13. Недействительность какого-либо положения настоящих Правил не влечет за собой недействительность
                остальных положений. Эти правила имеют общий вид.<br/>
            </p>
        </div>
    );
};

export default Rulls2;