import React from 'react';
import classes from "./cub.module.scss";

const Cub = () => {
    return (
        <div>
            <div className={classes.wrap}>
                <div className={classes.cube}>
                    <div className={classes.front}>
                       <p> Мы всегда идём на встречу Вам, потому что Вы точно знаете, что необходимо Вашим клиентам.
                       </p>
                    </div>
                    <div className={classes.back}>
                      <p>  Если мы договорились, то всё будет сделано в рамках договоренностей.
                      </p>
                    </div>
                    <div className={classes.top}>
                       <p> Наша политика определения цены, основывается на том, что мы можем всегда найти общий знаменгатель.
                    </p>
                    </div>
                    <div className={classes.bottom}>
                      <p>  Мы всегда найдём решение воппроса, которое устроит всех.
                    </p>
                    </div>
                    <div className={classes.left}>
                      <p>  Качественная и эффективная работа, это наш лозунг.
                    </p>
                    </div>
                    <div className={classes.right}>
                    <p> Вместе мы добъёмся процветания и развития. Для нас это очень важно.
                    </p>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Cub;