import React, {useState} from 'react';
import classes from "./term.module.scss";
import {Link} from "react-router-dom";
import {Contactus5} from "../../contactus/contactus1/contactus5";
import {Contactus6} from "../../contactus/contactus1/contactus6";
import {Contactus4} from "../../contactus/contactus1/contactus4";

const Term = () => {

    const [isShown, setIsShown] = useState(false)
    const [isShown1, setIsShown1] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    const [isShown3, setIsShown3] = useState(false)
    const [isShown4, setIsShown4] = useState(false)
    const [isShown5, setIsShown5] = useState(false)

    const handleClick = () => {
        setIsShown(current => !current)
    };

    const handleClick1 = () => {
        setIsShown1(current => !current)
    };

    const handleClick2 = () => {
        setIsShown2(current => !current)
    };

    const handleClick3 = () => {
        setIsShown3(current => !current)
    };

    const handleClick4 = () => {
        setIsShown4(current => !current)
    };

    const handleClick5 = () => {
        setIsShown5(current => !current)
    };

    return (
        <div className={classes.Home}>
            <Link to='/'><p className={classes.Home__block1}>Вернуться на главную</p></Link><br/><br/><br/><br/>
            <p className={classes.Home__sale__text1}>Отзывы о нас</p>
            <div className={classes.Home__sale__sale1}>
                <div className={classes.Home__sale__sale1__block1}>
                    <div className={classes.Home__sale__sale1__block1__text1}>Михаил. Начинающий селлер.</div>
                    <div className={classes.Home__sale__sale1__block1__text2}>Я очень доволен качеством и взаимодействием.
                        Самое главное для меня, что со мной постоянно общались и объясняли, что и когда происходит.
                        Ребята красавцы!!!
                    </div>
                    {/*<div className={classes.Home__sale__sale1__block1__text3}>*/}
                    {/*    стоимость обслуживания 10000 рублей ежемесячно*/}
                    {/*</div>*/}
                    {/*<button className={classes.Home__sale__sale1__block1__btn1} onClick={handleClick}>*/}
                    {/*    Нажмите и узнайте подробное описание*/}
                    {/*</button>*/}
                </div>
                <div className={classes.Home__sale__sale1__block2}>
                    <div className={classes.Home__sale__sale1__block2__text1}>Анастасия Иванова.</div>
                    <div className={classes.Home__sale__sale1__block2__text2}>Мы пользуемся услугами нескольких
                        ФФ, в том числе и ФФ Старт. Рекомендаю, очень ответственный подход.
                    </div>
                    {/*<div className={classes.Home__sale__sale1__block2__text2}>*/}
                    {/*    менеджер по продажам 20000 рублей ежемесячно*/}
                    {/*</div>*/}
                    {/*<button className={classes.Home__sale__sale1__block2__btn2} onClick={handleClick1}>*/}
                    {/*    Нажмите и узнайте подробное описание*/}
                    {/*</button>*/}
                </div>
                <div className={classes.Home__sale__sale1__block3}>
                    <div className={classes.Home__sale__sale1__block3__text1}>Василиса.</div>
                    <div className={classes.Home__sale__sale1__block3__text2}>У меня товар пришёл раньше и остатков на
                        ВБ практически не было, по моей просьбе, ребята всё забрали и быстро обработали. Спасибо большое.
                    </div>
                    {/*<div className={classes.Home__sale__sale1__block3__text3}>20% от прибыли ежемесячно</div>*/}
                    {/*<button className={classes.Home__sale__sale1__block3__btn3} onClick={handleClick2}>*/}
                    {/*    Нажмите и узнайте подробное описание*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className={classes.Home__sale1__sale2}>
                <div>
                    <div className={classes.Home__sale1__sale2__block1__menu1}
                         style={{display: isShown ? 'block' : 'none'}}>
                        <ul className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>В наличие у Вас не
                                более 100 товаров или услуг.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Большее количество товаров или услуг, предполагает
                                        создание интернет-магазина.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Создание сайта.
                                Стоимость 10000 рублей, срок до 14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Предоплата 1000 рублей, 9000 рублей оплачиваете после готовности сайта.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        До создания сайта мы должны согласовать техническое решение,
                                        логотип и домен.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После всех согласований создаем сайт.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем и запускаем сайт.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Хостинг, домен,
                                логотип в подарок.
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Подготовка и запуск
                                рекламной компании в подарок. Срок до 3 дней. Срок корректировки рекламной компании до
                                14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем бюджет рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После подготовки рекламной компании Вам будет необходимо пополнить
                                        первоначальный рекламный бюджет в размере 1500 рублей. В дальнейшем
                                        рекламная компания будет оплачиваться Вами по утвержденному бюджету.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Запускаем рекламу, после поступления первоначальной оплаты.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Сопровождение
                                рекламной компании и сайта компании. 10000 рублей в месяц.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Сопровождение начинается через 14 дней, после запуска рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Оплата в размере 100% производиться до 5 числа каждого месяца.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Первый месяц оплачивается в зависимости от количества дней, которое велось
                                        или будет вестись сопровождение.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <button className={classes.Home__sale1__sale2__block1__text2} onClick={handleClick3}
                        >Заказать услугу
                        </button>
                        <div className={classes.Home__sale1__sale2__block1__div1}
                             style={{display: isShown3 ? 'block' : 'none'}}><Contactus4/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.Home__sale1__sale2__block1__menu2}
                         style={{display: isShown1 ? 'block' : 'none'}}>
                        <ul className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>В наличие у Вас не
                                более 100 товаров или услуг.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Большее количество товаров или услуг, предполагает
                                        создание интернет-магазина.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Создание сайта.
                                Стоимость 10000 рублей, срок до 14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Предоплата 1000 рублей, 9000 рублей оплачиваете после готовности сайта.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        До создания сайта мы должны согласовать техническое решение,
                                        логотип и домен.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После всех согласований создаем сайт.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем и запускаем сайт.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Хостинг, домен,
                                логотип в подарок.
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Подготовка и запуск
                                рекламной компании в подарок. Срок до 3 дней. Срок корректировки рекламной компании до
                                14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем бюджет рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После подготовки рекламной компании Вам будет необходимо пополнить
                                        первоначальный рекламный бюджет в размере 1500 рублей. В дальнейшем
                                        рекламная компания будет оплачиваться Вами по утвержденному бюджету.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Запускаем рекламу, после поступления первоначальной оплаты.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Сопровождение
                                рекламной компании и сайта компании. 10000 рублей в месяц.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Сопровождение начинается через 14 дней, после запуска рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Оплата в размере 100% производиться до 5 числа каждого месяца.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Первый месяц оплачивается в зависимости от количества дней, которое велось
                                        сопровождение.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Организация продаж.
                                Стоимость 20000 рублей ежемнсячно. Срок до 7 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Согласование задач менеджера по продажам.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Установка и отладка сервиса "Мой склад". До 3 дней.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Запуск работы отдела продаж. До 4 дней.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Оплата в размере 100% производиться до 5 числа каждого месяца.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Первый месяц оплачивается в зависимости от количества дней, которое велись или
                                        будут вестись продажи.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <button className={classes.Home__sale1__sale2__block1__text3} onClick={handleClick4}
                        >Заказать услугу
                        </button>
                        <div className={classes.Home__sale1__sale2__block1__div2}
                             style={{display: isShown4 ? 'block' : 'none'}}><Contactus5/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.Home__sale1__sale2__block1__menu3}
                         style={{display: isShown2 ? 'block' : 'none'}}>
                        <ul className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>В наличие у Вас не
                                более 100 товаров или услуг.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Большее количество товаров или услуг, предполагает
                                        создание интернет-магазина.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Создание сайта.
                                Стоимость 10000 рублей, срок до 14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Предоплата 1000 рублей, 9000 рублей оплачиваете после готовности сайта.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        До создания сайта мы должны согласовать техническое решение,
                                        логотип и домен.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После всех согласований создаем сайт.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем и запускаем сайт.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Хостинг, домен,
                                логотип в подарок.
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Подготовка и запуск
                                рекламной компании в подарок. Срок до 3 дней. Срок корректировки рекламной компании до
                                14 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Утверждаем бюджет рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        После подготовки рекламной компании Вам будет необходимо пополнить
                                        первоначальный рекламный бюджет в размере 1500 рублей. В дальнейшем
                                        рекламная компания будет оплачиваться Вами по утвержденному бюджету.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Запускаем рекламу, после поступления первоначальной оплаты.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Сопровождение
                                рекламной компании и сайта компании. 10000 рублей в месяц.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Сопровождение начинается через 14 дней, после запуска рекламной компании.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Оплата в размере 100% производиться до 5 числа каждого месяца.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Первый месяц оплачивается в зависимости от количества дней, которое велось или
                                        будет вестись сопровождение.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Организация продаж.
                                Стоимость 20000 рублей ежемнсячно. Срок до 7 дней.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Согласование задач менеджера по продажам.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Установка и отладка сервиса "Мой склад". До 3 дней.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Запуск работы отдела продаж. До 4 дней.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Оплата в размере 100% производиться до 5 числа каждого месяца.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Первый месяц оплачивается в зависимости от количества дней, которое велись
                                        продажи.
                                    </li>
                                </ul>
                            </li>
                            <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Со второго
                                месяца мы становимся Вашим отделом по развитию и вместо ежемесячных платежей
                                будем получать 20% от прибыли каждый месяц до 5 числа.
                                <ul>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Соответственно все траты, касающиеся развития, будем нести мы.
                                    </li>
                                    <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                        Единственное, что останется за Вами, это оплата рекламного аккаунта.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <button className={classes.Home__sale1__sale2__block1__text1} onClick={handleClick5}
                        >Заказать услугу
                        </button>
                        <div className={classes.Home__sale1__sale2__block1__div}
                             style={{display: isShown5 ? 'block' : 'none'}}><Contactus6/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Term;