import React, {useState} from 'react';
import classes from "./home.module.scss";
import Header1 from "../../header/header1/header1";
import Menu from "../../menu/menu";
import Cub from "../../animate/cub/cub";
import Menu1 from "../../menu/menu1";
import Footer from "../../footer/footer";
import {Contactus1} from "../../contactus/contactus1";
import {Contactus2} from "../../contactus/contactus2";
import {Contactus3} from "../../contactus/contactus3";

const Home = () => {
    const [isShown, setIsShown] = useState(false)
    const [isShown1, setIsShown1] = useState(false)
    const [isShown2, setIsShown2] = useState(false)
    const [click, setClick] = useState(false)
    const [click1, setClick1] = useState(false)
    const [click2, setClick2] = useState(false)

    const clickHandler = () => {
        setClick(current => !current)
    };

    const clickHandler1 = () => {
        setClick1(current => !current)
    };

    const clickHandler2 = () => {
        setClick2(current => !current)
    };

    const handleClick = () => {
        setIsShown(current => !current)
    };

    const handleClick1 = () => {
        setIsShown1(current => !current)
    };

    const handleClick2 = () => {
        setIsShown2(current => !current)
    };

    return (
        <div className={classes.Home}>
            <Header1/>
            <Menu/>
            <Menu1/>
            {/*<h3 className={classes.Home__block__block1__text1}>Фулфилмент СТАРТ начнёт принимать товар с 17 мая!!!<br/>*/}
            {/*    Мы рады Вас видеть будем у нас.</h3>*/}
            <div className={classes.Home__block}>
                {/*<img className={classes.Home__block__img1} src={image3} alt='text'/>*/}
                <ul className={classes.Home__block__block1}>
                    <p className={classes.Home__block__block1__text1}>НАШИ ПРЕИМУЩЕСТВА</p>
                    <li className={classes.Home__block__block1__li1}>Нацеленность на результат</li>
                    <li className={classes.Home__block__block1__li1}>Стремление к долгосрочному сотрудничеству</li>
                    <li className={classes.Home__block__block1__li1}>Вовлеченность во всеми процессы Вашего дела</li>
                    <li className={classes.Home__block__block1__li1}>Доступные цены</li>
                    <li className={classes.Home__block__block1__li1}>Отсутствие скрытых платежей</li>
                    <li className={classes.Home__block__block1__li1}>Стоимость и сроки утверждаются на старте</li>
                    <li className={classes.Home__block__block1__li1}>Мы заинтересованы в Вашем росте и процветании, так
                        как мы растём вместе с Вами. Ваши победы, это наши победы
                    </li>
                    <li className={classes.Home__block__block1__li1}>Добро пожаловать в Фулфилмент EST!!!</li>
                </ul>
            </div>
            <p className={classes.Home__sale__text1}>Наши цены</p>
            <div className={classes.Home__sale}>
                <div className={classes.Home__sale__sale1}>
                    <div className={classes.Home__sale__sale1__block1}>
                        <div className={classes.Home__sale__sale1__block1__text1}>Логистика, хранение и продвижение
                        </div>
                        {/*<div className={classes.Home__sale__sale1__block1__text2}>Стоимость пакета 10000 рублей*/}
                        {/*</div>*/}
                        {/*<div className={classes.Home__sale__sale1__block1__text3}>*/}
                        {/*    стоимость обслуживания 10000 рублей ежемесячно*/}
                        {/*</div>*/}
                        <button className={classes.Home__sale__sale1__block1__btn1} onClick={handleClick}>Что входит в
                            стоимость, нажмите и узнайте
                        </button>
                    </div>
                    <div className={classes.Home__sale__sale1__block2}>
                        <div className={classes.Home__sale__sale1__block2__text1}>Обработка товара, формирование
                            коробов,
                            погрузочно-разгрузочные работы
                        </div>
                        {/*<div className={classes.Home__sale__sale1__block2__text2}>Стоимость пакета 10000 рублей*/}
                        {/*</div>*/}
                        {/*<div className={classes.Home__sale__sale1__block2__text2}>*/}
                        {/*    менеджер по продажам 20000 рублей ежемесячно*/}
                        {/*</div>*/}
                        {/*<div className={classes.Home__sale__sale1__block2__text3}>*/}
                        {/*    стоимость обслуживания 10000 рублей ежемесячно*/}
                        {/*</div>*/}
                        <button className={classes.Home__sale__sale1__block2__btn2} onClick={handleClick1}>Что входит в
                            стоимость, нажмите и узнайте
                        </button>
                    </div>
                    <div className={classes.Home__sale__sale1__block3}>
                        <div className={classes.Home__sale__sale1__block3__text1}>Цена упаковки, с учётом нашего
                            материала
                        </div>
                        {/*<div className={classes.Home__sale__sale1__block3__text2}>Стоимость пакета 10000 рублей*/}
                        {/*</div>*/}
                        {/*<div className={classes.Home__sale__sale1__block3__text3}>20% от прибыли ежемесячно</div>*/}
                        <button className={classes.Home__sale__sale1__block3__btn3} onClick={handleClick2}>Что входит в
                            стоимость, нажмите и узнайте
                        </button>
                    </div>
                </div>
                <div className={classes.Home__sale1__sale2}>
                    <div className={classes.Home__sale1__sale2__block1}>
                        <div className={classes.Home__sale1__sale2__block1__menu1}
                             style={{display: isShown ? 'block' : 'none'}}>
                            <ul className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li4}>Забор товара</li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Забор товара с ТЯК,
                                    Садовода, Южных ворот - до 1 куб.м 1000 руб.<br/>
                                    - от 1 куб.м от 1000 руб.
                                    <br/>(стоимость забора будет зависеть от того находится ли товар
                                    на карго (въезд от 1500 руб.), объёма товара, количество мест.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Забор товара с иных
                                    точек обговаривается индивидуально
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li4}>Доставка до склада
                                    МП
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Доставка до склада
                                    МП
                                    от 1 до 9 коробов (до 1 куб.м.) - от 400 руб. за 1 короб
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Доставка 1 паллеты
                                    от 2000 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Доставка от 2 паллет
                                    от 1700 руб. за 1 паллету
                                </li>
                                {/*<li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Отправка по FBS*/}
                                {/*    150 руб. 1 короб*/}
                                {/*</li>*/}
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li4}>Хранение товара</li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Бесплатное хранение
                                    до 14 дней
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Хранение 1 короба с
                                    15 дня - 20 руб. в сутки
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Хранение 1 паллеты с
                                    15 дня - 60 руб. в сутки
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li4}>Забор товара с ПВЗ
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Забор до 5 ед. с
                                    одного ПВЗ, при этом сумма ширины, высоты и длины не более 60 см - 150 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu1__ul1__li2}>Иное количество и
                                    габариты обговариваются индивидуально
                                </li>
                            </ul>
                            {/*<button onClick={clickHandler}*/}
                            {/*        className={classes.Home__sale1__sale2__block1__text1}>Заказать услугу*/}
                            {/*</button>*/}
                            <div className={classes.Home__sale1__sale2__block1__div}
                                 style={{display: click ? 'block' : 'none'}}><Contactus1/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Home__sale1__sale2__block1}>
                        <div className={classes.Home__sale1__sale2__block1__menu2}
                             style={{display: isShown1 ? 'block' : 'none'}}>
                            <ul className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li4}>Обработка товара
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Пересчёт товара при
                                    приёме - 1 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Проверка
                                    соответствия
                                    размеров - 2 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Замер упаковки для
                                    карточки в ЛК - 20 руб. за 1 замер
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Замер товара по
                                    Вашему заданию - 150 руб. за 1 артикул товара
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Проверка на брак
                                    - от 5 руб. за 1 ед.
                                </li>
                                {/*<li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Фасовка товара по*/}
                                {/*    коробам - 1 руб. за 1 ед.*/}
                                {/*</li>*/}
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Маркировка товара
                                    - от 3 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Биркитирование
                                    - от 5 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Вложения инструкций,
                                    сертификатов и.т.п - от 3 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Любые дополнительные
                                    работы по согласованию - от 3 руб. за 1 ед.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li4}>Формирование коробов
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Короб 60х40х40 -
                                    100 руб.
                                </li>
                                {/*<li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Короб 55х35х35 -*/}
                                {/*    90 руб.*/}
                                {/*</li>*/}
                                {/*<li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Короб 38х38х26 -*/}
                                {/*    75 руб.*/}
                                {/*</li>*/}
                                {/*<li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Маркировка 1 короба*/}
                                {/*    1 ШК - 5 руб. за 1 ед.*/}
                                {/*</li>*/}
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li4}>Погрузочно-разгрузочные
                                    работы
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Погрузка или
                                    разгрузка
                                    1 короба или мешка - 20 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Снятие обрешётки -
                                    100 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu2__ul1__li2}>Паллетирование -
                                    150 руб.
                                </li>
                            </ul>
                            {/*<button onClick={clickHandler1}*/}
                            {/*        className={classes.Home__sale1__sale2__block1__text1}>Заказать услугу*/}
                            {/*</button>*/}
                            <div className={classes.Home__sale1__sale2__block1__div1}
                                 style={{display: click1 ? 'block' : 'none'}}><Contactus2/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Home__sale1__sale2__block1}>
                        <div className={classes.Home__sale1__sale2__block1__menu3}
                             style={{display: isShown2 ? 'block' : 'none'}}>
                            <ul className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>Упаковка
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>БОПП пакет
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>БОПП пакет 8х10 см.
                                    - 3 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>БОПП пакет 15х12 см.
                                    - 4 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>БОПП пакет 35х25 см.
                                    - 5 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>БОПП пакет 45х30 см.
                                    - 6 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>Зип Лок пакет
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    15х20 см. - 3 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    20х30 см. - 4 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    30х40 см. - 5 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    40х50 см. - 7 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>Зип Лок пакет
                                    с бегунком
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    с бегунком 20х30 см. - 7 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    с бегунком 30х40 см. - 10 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    с бегунком 40х50 см. - 14 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>Зип Лок пакет
                                    с бегунком 50х70 см. - 20 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>ВПП (пупырка,
                                    ориентировочные цены)
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>ВПП
                                    15х20 см. - от 8 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>ВПП
                                    30х40 см. - от 14 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>ВПП
                                    40х50 см. - от 20 руб.
                                </li>
                                <li className={classes.Home__sale1__sale2__block1__menu3__ul1__li2}>ВПП
                                    50х70 см. - от 30 руб.
                                </li>
                                {/*<li className={classes.Home__sale1__sale2__block1__menu3__ul1__li4}>Упаковка товара в*/}
                                {/*    Ваши пакеты - 5 руб. за 1 ед.*/}
                                {/*</li>*/}
                            </ul>
                            {/*<button onClick={clickHandler2}*/}
                            {/*        className={classes.Home__sale1__sale2__block1__text1}>Заказать услугу*/}
                            {/*</button>*/}
                            <div className={classes.Home__sale1__sale2__block1__div2}
                                 style={{display: click2 ? 'block' : 'none'}}><Contactus3/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.Home__block1}>
                    <h1 className={classes.Home__block1__text1}>Почему нас выбирают</h1>
                    <Cub/>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;